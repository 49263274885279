*{
    padding: 0;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.footer-principal-container{
    display: flex;
    justify-content: center;
}

.footer-all-container{
    max-width: 1538px;

    width: 100%;
    margin-top: 20px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgb(209,0,0), rgb(84, 2, 2));
}


.footer-container{
    background-color: rgb(255, 255, 255);
    width: 94%;
    margin-top: 4%;
    height: 96%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

}



.footer-loja-titulo-principal{
    width: 94%;
    height: 30px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: underline;
    color: grey;
    font-size: 13px;
}

.footer-informacoes-loja{
    width: 94%;
}

.footer-informacoes-loja ul li{
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-weight: bold;
    font-size: 13px;
    list-style: none;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.footer-informacoes-loja ul li img{
    height: 20px;
    margin-right: 10px;
}

.footer-informacoes-loja ul li a{
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.footer-informacoes-loja ul li a span{
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}


.footer-informacoes-container-800px{
    display: none;
}

.iconPngEmail{
    width: 20px;
    height: 1px;
}

.logoYoutube{
    width: 20px;
    height: 1px;
}

.footer-informacoes-loja ul li a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.left-margin{
    margin-left: 3px;
}.left-margin span{
    margin-left: 5.5px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
}



@media screen and (min-width: 940px){
    .footer-all-container{
        max-width: 1538px;
        width: 100%;
        margin-top: 20px;
        height: 500px;
        
        background: linear-gradient(to bottom, rgb(209,0,0), rgb(84, 2, 2));
    }
    
    .footer-loja-titulo-principal:nth-of-type(1){
        display: none;
    }

    .footer-loja-titulo-principal:nth-of-type(3){
        display: none;
    }

    .footer-informacoes-loja:nth-of-type(2){
        display: none;
    }

    .footer-informacoes-loja:nth-of-type(4){
        display: none;
    }

    .footer-informacoes-container-800px{
        width: 94%;
        display: flex;
    }

    .footer-informacoes-left-800px{
        width: 50%;
    }

    .footer-informacoes-right-800px{
        width: 50%;
    }

    .footer-loja-titulo-principal-800px{
        width: 94%;
    height: 30px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: underline;
    color: grey;
    font-size: 13px;
    }

    .footer-informacoes-loja-800px{
        width: 94%;
    }
    
    .footer-informacoes-loja-800px ul li{
        display: flex;
        margin-top: 10px;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        list-style: none;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        
    }
    
    .footer-informacoes-loja-800px ul li img{
        height: 20px;
        margin-right: 10px;
    }
    
    .footer-informacoes-right-1200px{
        display: none;
    }

    .footer-container{
        width: 97%;
        margin-top: 10px;
    }

    .footer-informacoes-loja-800px ul li a{
        display: flex;
        align-items: center;
        text-decoration: none;
        color: black;
        font-weight: bold;
        
    }

    .left-margin{
        margin-left: 3px;
    }.left-margin span{
        margin-left: 3.5px;
    }

    .footer-informacoes-left-800px .footer-informacoes-loja-800px ul li a span{
        color: black;
        font-weight: bold;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    }

    .footer-informacoes-left-800px .footer-informacoes-loja-800px ul li a{
        color: black;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    }

    .footer-informacoes-right-800px .footer-informacoes-loja-800px ul li a{
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    }

    
    

}

@media screen and (min-width: 1200px){
    .footer-all-container{
        margin-top: 20px;
        max-width: 1538px;
         width: 100%;
        height: 350px;
       
        background: linear-gradient(to bottom, rgb(209,0,0), rgb(84, 2, 2));
    }

    .footer-informacoes-left-800px{
        width: 40%;
    }

    .footer-informacoes-right-800px{
       
        width: 40%;
    }

    .footer-informacoes-right-800px .footer-loja-titulo-principal-800px{
        margin-left: 100px;
    }

    .footer-informacoes-right-800px .footer-informacoes-loja-800px{
        margin-left: 100px;
    }

    .footer-informacoes-right-1200px{
        display: block;
        width: 20%;
    }

    .footer-informacoes-right-1200px ul li a{
        text-decoration: none;
        color: black;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    }

    .footer-informacoes-right-1200px ul li a:hover{
        text-decoration: none;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

        color: rgb(209,0,0);
    }

    .footer-loja-titulo-principal:nth-of-type(6){
        display: none;
    }

    .footer-informacoes-loja:nth-of-type(7){
        display: none;
    }


    .iconPngEmail{
        width: 20px;
        height: 1px;
    }

    .footer-informacoes-loja-800px ul li a{
        display: flex;
        align-items: center;
        text-decoration: none;
        color: black;
    }
}