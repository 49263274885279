*{
    padding: 0;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;

}

.carro-principal-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.carro-all-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;
}

.carro-voltar-estoque{
    width: 100%;
    width: 94%;
}


.carro-imagem-principal{
    width: 94%;
    height: 350px;
    border-radius: 10px;
    border: solid 1px rgba(199, 198, 198, 0.601);
}

.carro-imagem-principal img{
    height: 350px;
    width: 100%;
    border-radius: 10px;
}


.carro-informacoes{
    width: 91.5%;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
}

.carro-informacoes-container-right{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.carro-informacoes-container-right span{
    color: black;
    font-weight: bold;
}

.carro-informacoes-container-right span:nth-of-type(2){
    color: grey;
}

.carro-informacoes-container-right span:nth-of-type(3){
    color: grey;
}


.carro-informacoes-container-left{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carro-informacoes-container-left span{
    color: rgb(209,0,0);
    font-weight: bold;
}

.box-informacoes{
    width: 91.5%;
    margin-top: 5px;
    height: 80px;
    display: flex;
    justify-content: space-around;
}

.box-informacoes-left{
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px rgb(78, 66, 66);
    background-color: rgb(203, 203, 203);
}

.box-informacoes-left span{
    font-weight: bold;
}

.box-informacoes-center{
    border: solid 1px rgb(78, 66, 66);    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(203, 203, 203);

}

.box-informacoes-center span{
    font-weight: bold;
}

.box-informacoes-right{
    border: solid 1px rgb(78, 66, 66);
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(203, 203, 203);

}

.box-informacoes-right span{
    font-weight: bold;
}

.carro-informacoes ul{
    height: 100%;
    width: 100%;
}

.carro-informacoes ul li{
    height: 50%;
    list-style: none;
    display: flex;
}

.carro-informacoes ul .carro-informacoes-1{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 50%;
}

.carro-informacoes ul .carro-informacoes-1 span{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    border: solid 1px rgba(218, 218, 218, 0.793);
    background-color: rgb(238, 238, 238);
    height: 90%;
    width: 30%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    border-radius: 10px;

}

.carro-formações-esquerda{
    width: 50%;
    font-family: Arial, Helvetica, sans-serif;
}

.carro-formações-esquerda ul{
    height: 100%;
    width: 100%;
}


.carro-formações-esquerda ul li{
    height: 33.3%;
    width: 100%;
    display: flex;
    align-items: center;
}

.carro-formações-esquerda ul li span{
    margin-left: 10px;
}

.carro-formações-esquerda ul li:nth-of-type(1){
    align-items: end;
}

.carro-formações-esquerda ul li:nth-of-type(1) span{
    font-weight: bold;
    font-size: 22px;
}



.carro-formações-esquerda ul li:nth-of-type(2) span{
    font-weight: bold;
    color: grey;
    font-size: 14px;

}







.carro-formações-esquerda ul li:nth-of-type(3){
    align-items: start;
}

.carro-formações-esquerda ul li:nth-of-type(3) span{
    color: grey;
    font-weight: bold;
    font-size: 14px;
}



.carro-formações-direita{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(209,0,0);
    font-size: 22px;
}



.carro-opcionais{
    margin-top: 10px;
    width: 94%;
}

.carro-opcionais-titulo{
    height: 30px;
    display: flex;
    align-items: center;
}



.carro-opcionais-titulo span{
    margin-left: 10px;
    color: rgb(209,0,0);
    font-weight: bold;
    text-decoration: underline;
    font-size: 13px;
}

.carro-opcionais-conteudo{
    width: 100%;
    margin-left: 10px;
}

.carro-opcionais-conteudo ul{
    width: 94%;
    list-style: none;
}
.carro-opcionais-conteudo span{
    color: rgb(102, 102, 102);
    font-size: 12px;
    list-style:armenian;
}



.carro-observações{
    margin-top: 20px;
    width: 94%;
}

.pergunta-gostou{
    height: 30px;
    display: flex;
    align-items: center;
}

.pergunta-gostou span{
    margin-left: 10px;
    color: rgb(209,0,0);
    font-weight: bold;
    text-decoration: underline;
    font-size: 13px;
}

.carro-observações p{
    margin-left: 10px;
    text-align: left;
    color: grey;
}

.carro-observações p a{
    color: grey;
}

.carro-container-baixo-1000px{
    display: none;
}


.carro-container-baixo{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carro-container-baixo-titulo{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
    color: rgba(0, 131, 140, 0.702);
    text-decoration: underline;
}

.carro-container-baixo-relacionados{
    margin-top: 20px;
    width: 400px;
    display: grid;
    grid-template-columns: repeat((1, 1fr));
    align-items: center;
    justify-content: center;
}


.carro-container-right-1000px, .carro-container-left-1000px{
    display: none;
}


.nenhum-carro-relacionado{
    color: grey;
    font-weight: bold;
}

.nenhum-carro-relacionado-1000px{
    display: none;
}






/* CARRO NÃO ENCONTRADO */
.carro-error-carro-detalhes-container{
    margin-top: 20px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carro-error-carro-detalhes-container h1{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: rgb(209,0,0);
    font-size: 20px;
    width: 80%;
    display: flex;
    justify-content: center;
}

.carro-error-carro-detalhes-container span{
    color: black;
    font-weight: bold;
}

.carro-error-carro-detalhes-container a {
    color: black;
}



/* CARRO CARREGANDO LAYOUT */
.carro-detalhes-carregando{
    margin-top: 20px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carro-detalhes-carregando-centralized{
    height: 100%;
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
}


.carro-detalhes-carregando-centralized span{
    color: rgb(209,0,0);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: bold;
    animation: animate 2s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
    0%{
        transform: translateY(0px);
    }
    20%{
        transform: translateY(-20px);
    }
    40%, 100%{
        transform: translateY(0px);
    }
}

.reiniciar-pagina{
    margin-top: 30px;
    cursor: pointer;
    background-color: rgb(226, 226, 226);
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

@media screen and (min-width: 720px) {
    
.carro-imagem-principal{
    width: 94%;
    height: 450px;
    border-radius: 10px;
    border: solid 1px rgba(199, 198, 198, 0.601);
}

.carro-imagem-principal img{
    height: 450px;
    width: 100%;
    border-radius: 10px;
}



}

@media screen and (min-width: 770px) {
    .carro-container-baixo-relacionados{
        margin-top: 20px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
    }
}


@media screen and (min-width: 1000px){
    .carro-all-container{
        display: flex;
        
    }

    .box-informacoes{
        display: none;
    }

    .carro-container-baixo{
        display: none;
    }

    .carro-container-top-1000px{
        width: 100%;
        display: flex;
    }

    .carro-all-container-100px{
        width: 94%;
        display: flex;
        flex-direction: column;
    }

    .carro-container-left-1000px{
        display: block;
        width: 60%;

    }

    .voltar-estoque-100px{
        height: 50px;
        display: flex;
        align-items: center;
    }

    .carro-imagem-container-1000px{
        height: 400px;
        width: 99%;
        margin-left: 2.5px;
        margin-top: 20px;
    }

    .image-carro-1000px {
        height: 400px;
        width: 100%;
    }

    .pergunta-gostou-1000px{
        height: 30px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: rgb(209,0,0);
        text-decoration: underline;
        font-weight: bold;
        margin-left: 3px;
    }

    .carro-observacao-1000px{
        width: 99%;
        margin-left: 3px;
        margin-top: 20px;

    }

    .carro-observacao-1000px p{
        color: grey;
        font-weight: bold;
    }

    .carro-observacao-1000px p a{
        text-decoration: underline;
        color: grey;

    }

    .carro-container-right-1000px{
        display: block;
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .carro-informacoes-right-1000px{
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-top: 70px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .linha-marca-modelo-1000px{
        font-weight: bold;
        font-size: 30px;
    }

    .linha-motor-comb-carroce-1000px{
        font-size: 16px;
        color: grey;
        font-weight: bold;
        margin-top: 10px;
    }

    .ano-fab-1000px{
        font-size: 14px;
        color: grey;
        font-weight: bold;
        margin-top: 10px;

    }

    .valor-1000px{
        margin-top: 10px;
        font-size: 26px;
        color: rgb(209,0,0);
        font-weight: bold;
    }

    .carro-informacoes-caixa-1000px{
        width: 100%;
        margin-top: 10px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: 40px;
    }

    .carro-informacoes-caixa-1000px span{
        color: black;
        border: solid 1px rgba(218, 218, 218, 0.793);
        background-color: rgb(238, 238, 238);
        font-weight: bold;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
    
    .carro-voltar-estoque{
        display: none;
    }
    
    .carro-opicionais-titulo-1000px{
        margin-top: 10px;
        width: 90%;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .carro-opicionais-titulo-1000px span{
        font-size: 13px;
        font-weight: bold;
        color: rgb(209,0,0);
        text-decoration: underline;
    }

    .carro-opcionais-conteudo-1000px{
        width: 90%;
        display: flex;
    }

    .carro-opcionais-left-1000px{
        width: 50%;
    }

    .carro-opcionais-left-1000px ul{
        list-style: none;
    }

    .carro-opcionais-left-1000px ul li{
        color: grey;
        font-size: 12px;
        margin-top: 5px;
    }

    .carro-opcionais-right-1000px{
        width: 50%;

    }

    .carro-opcionais-right-1000px ul{
        list-style: none;
    }

    .carro-opcionais-right-1000px ul li{
        color: grey;
        margin-left: 16px;
        font-size: 12px;
        margin-top: 5px;
    }

    .carro-imagem-principal{
        display: none;

    }
    
    .carro-informacoes{
        display: none;

    }

    .carro-opcionais{
        display: none;
    }

    .carro-observações{
        display: none;
    }


    .carro-container-baixo-1000px{
        display: block;
        margin-top: 80px;
    }

    .carro-container-baixo-titulo-1000px{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 131, 140, 0.702);
        text-decoration: underline;
    }

    .carro-container-baixo-relacionados-1000px{
        margin-top: 20px;
    }

    .carro-container-baixo-relacionados-1000px{
        margin-top: 20px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1000px){
    .carro-all-container{
        max-width: 1538px;
    
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        flex-direction: column;
    }
    .carro-imagem-container-1000px{
        height: 500px;
        width: 99%;
        margin-left: 2.5px;
        margin-top: 20px;
    }

    .image-carro-1000px {
        height: 500px;
        width: 100%;
    }

    .nenhum-carro-relacionado-1000px{
        display: flex;
        align-items: center;
        justify-content: center;
        color: grey;
        font-weight: bold;
    }

}

@media screen and (min-width: 1538px){
    .carro-imagem-container-1000px{
        height: 550px;
        width: 99%;
        margin-left: 2.5px;
        margin-top: 20px;
    }

    .image-carro-1000px {
        height: 550px;
        width: 100%;
    }

    .carro-container-baixo-relacionados-1000px{
        margin-top: 20px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        justify-content: center;
    }

}