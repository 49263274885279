*{
    padding: 0;
    margin: 0;
}

.emp-all-containers{
    width: 100%;
    margin-top: 20px;
}

.emp-container-centralize{
    position: relative;
    left: -100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: appearEmprestimoMobile 0.6s forwards;
}
@keyframes appearEmprestimoMobile {
    0%{
        left: -100%;
        opacity: 0;
    }50%{
        opacity: 0.4;
    }100%{
        left: 0;
        opacity: 1;
    }
}

.emp-info-pessoal{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.frase-negocio{
    font-size: 11.4px;
    margin-top: 5px;
    font-weight: bold;
}

.emp-info-pessoal h3{
    color: rgb(209, 0, 0);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
}

.emp-info-pessoal ul{
    width: 100%;
    margin-top: 20px;
}

.emp-info-pessoal ul li{
    margin-top: 15px;
    flex-direction: column;
    display: flex;
}



.emp-info-pessoal ul li label{
    color: grey;
    font-weight: bold;
    font-size: 13px;
}

.emp-info-pessoal ul li input{
    margin-top: 5px;
    height: 35px;
    border: solid 1px black;
    padding-left: 10px;
    border: solid 1px grey;
    border-radius: 5px;
}

.emp-info-pessoal h3:nth-of-type(2){
    margin-top: 20px;
}

.emp-info-pessoal h3:nth-of-type(3){
    margin-top: 20px;
}

.emp-info-pessoal button{
    margin-top: 50px;
    background-color: rgb(0, 89, 255);
    border-radius: 5px;
    height: 50px;
    width: 200px;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.emp-info-pessoal form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emp-info-750px{
    display: none;
}

.form-error{
    color: rgb(209, 0, 0);
    font-weight: bold;
    font-size: 15px;
    text-decoration: underline;
}

@media screen and (min-width: 750px) {
    .emp-info-pessoal{
        display: none;
    }


    .emp-info-750px{
        position: relative;
        left: -100%;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: appear2 0.8s forwards;
        border-radius: 10px;
    }

    .emp-info-750px form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .emp-info-750px h3{
        color: rgb(209, 0, 0);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 17px;
        text-decoration: underline;
    }


    .info-pessoais-750px{
        margin-top: 20px;
        width: 94%;
    }

    .info-dados-pessoais-750px{
        width: 100%;
        display: flex;
    }

    .info-dados-pessoais-esquerda-750px{
        width: 50%;
    }

    .info-dados-pessoais-direita-750px{
        width: 50%;
    }

    .info-dados-pessoais-esquerda-750px ul li{
        margin-top: 15px;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    
    
    
    .info-dados-pessoais-esquerda-750px ul li label{
        color: grey;
        font-weight: bold;
        font-size: 11px;
    }
    
    .info-dados-pessoais-esquerda-750px ul li input{
        margin-top: 5px;
        width: 90%;
        height: 35px;
        border: solid 1px grey;
        padding-left: 10px;
        border-radius: 5px;

    }


    .info-dados-pessoais-direita-750px ul li{
        margin-top: 15px;
        flex-direction: column;
        display: flex;
        align-items: center;

    }
    
    
    
    .info-dados-pessoais-direita-750px ul li label{
        color: grey;
        font-weight: bold;
        font-size: 11px;
    }
    
    .info-dados-pessoais-direita-750px ul li input{
        margin-top: 5px;
        width: 90%;
        height: 35px;
        border: solid 1px grey;
        padding-left: 10px;
        border-radius: 5px;

    }

    .info-separacao-750px{
        margin-top: 15px;
        display: flex;
        justify-content: center;
        max-width: 1538px;

    }

    .info-separacao-750px ul{
        width: 95%;
        max-width: 1538px;

    }


    .info-separacao-750px ul li{
        margin-top: 15px;
        flex-direction: column;
        display: flex;
        align-items: center;
        max-width: 1538px;


    }


    .info-carro-750px{
        margin-top: 20px;
        width: 94%;
        max-width: 1538px;

    }
    
    
    
    .info-separacao-750px  ul li label{
        color: grey;
        font-weight: bold;
        font-size: 11px;
        max-width: 1538px;

    }
    
    .info-separacao-750px  ul li input{
        margin-top: 5px;
        width: 100%;
        height: 35px;
        border: solid 1px grey;
        border-radius: 5px;
        padding-left: 10px;
        max-width: 1538px;

    }
    
    .emp-info-750px button{
        max-width: 1538px;

        position: relative;
        left: 0%;
        overflow: hidden;
        margin-top: 50px;
        background-color: rgb(0, 89, 255);
        border-radius: 5px;
        animation: appear2 0.8s forwards;
        height: 50px;
        width: 200px;
        border: none;
        color: white;
        font-size: 15px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        cursor: pointer;
    }

    @keyframes appear2 {
        0%{
            left: -100%;
            opacity: 0;

        } 50%{
            opacity: 0.4;
        }
        100%{
            left: 0%;
            opacity: 1;
        }
    }

    .emp-info-750px button:hover{
        background-color: blue;
        
    }


    .emp-info-750px h3:nth-of-type(1){
        margin-top: 20px;
        max-width: 1538px;

    }

    .emp-info-750px h3:nth-of-type(2){
        margin-top: 20px;
        max-width: 1538px;

    }
    
    .emp-info-750px h3:nth-of-type(3){
        margin-top: 20px;
        max-width: 1538px;

    }

    .form-error{
        font-size: 13px;
        margin-left: 7px;
        max-width: 1538px;

    }
    
}


@media screen and (min-width: 1100px) {
    .info-pessoais-750px{
        width: 70%;
        max-width: 1538px;

    }

    .emp-info-750px form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1538px;

    }

    .info-carro-750px{
        width: 70%;
        max-width: 1538px;

    }

    .info-preencher-espaco{
        margin-top: 100px;
        max-width: 1538px;

    }
}