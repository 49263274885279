*{
    padding: 0;
    margin: 0;
}

.contato-principal-container{
    width: 100%;
    display: flex;
    justify-content: center;
    
}

.contato-all-container{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contato-centralized-container{
    position: relative;
    animation: appearContatoMobile 0.6s forwards;
    left: -100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

@keyframes appearContatoMobile {
    0%{
        left: -100%;
        opacity: 0;
    }50%{
        opacity: 0.4;
    }100%{
        left: 0;
        opacity: 1;
    }
}

.contato-centralized-container h1{
    color: rgb(209,0,0);
    text-decoration: underline;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    width: 100%;
    display: flex;
}

.contato-centralized-container h1:nth-of-type(2){
    margin-top: 20px;
}

.contato-centralized-container span{
    margin-top: 20px;
    font-size: 15px;
    width: 100%;
    color: grey;
    font-weight: bold;
}

.contato-contatos{
    width: 100%;
    margin-top: 20px;
}

.contato-contatos ul{
    list-style: none;
    width: 100%;
}

.contato-contatos ul li{
    height: 100px;
    display: flex;
}


.contato-contatos ul li a{
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-bottom: solid 1px grey;
}

.contato-contatos ul li:nth-of-type(1) a{
    border-top: solid 1px grey;
}

.contato-contatos ul li a span{
    margin-left: 20px;
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    padding-bottom: 20px;
    font-weight: bold;
    display: flex;
    justify-content: start;
}

.contato-contatos ul li img{
    height:40px;
    width: 40px;
    border-radius: 100px;
}

.contato-centralized-container .localizacao-image{
    margin-top: 10px;
    width: 100%;
    
}

.contato-centralized-container .localizacao-image img{
    width: 100%;
}

.contato-centralized-container-1000px{
    display: none;
}

.contato-centralized-container h1:nth-of-type(2){
    color: rgb(0, 199, 209);
    text-decoration: underline;

}

.contato-centralized-container .black-color{
    color: black;
}


@media screen  and (min-width: 1000px){
   .contato-centralized-container{
    display: none;
   }

    .contato-centralized-container-1000px{
        display: flex;
        width: 100%;
    }
   

    .contato-left-container-1000px{
        position: relative;
        left: -100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: appearContatoLeft 0.6s forwards;
    }

    .contato-left-centralized{
        width: 80%;
    }

    .contato-left-container-1000px h1{
        color: rgb(209,0,0);
        font-size: 15px;
        text-decoration: underline;
    }

    .contato-left-centralized span{
        color: grey;
        font-weight: bold;
        font-size: 13px;
        margin-top: 20px;
    }

    .span-siganos{
        margin-top: 20px;
    }


    






    .contato-right-container-1000px{
        position: relative;
        left: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(208, 208, 208, 0.257);
        animation: appearContatoRight 0.6s forwards;
    }

    .contato-right-container-1000px h1{
        color: rgb(0, 199, 209);
        margin-top: 10px;
        padding-top: 10px;
        font-size: 15px;
        text-decoration: underline;
    }

    .contato-right-container-1000px span{
        color: black;
        font-weight: bold;
    }

    .contato-right-container-1000px span:nth-of-type(1){
        margin-top: 20px;
    }

    .contato-right-container-1000px img{
        width: 100%;
        margin-top: 30px;
    }
    
    
    @keyframes appearContatoLeft {
        0%{
            left: -100%;
            opacity: 0;
        }50%{
            opacity: 0.4;
        }100%{
            left: 0;
            opacity: 1;
        }
    }
    

    @keyframes appearContatoRight {
        0%{
            left: 100%;
            opacity: 0;
        }50%{
            opacity: 0.4;
        }100%{
            left: 0;
            opacity: 1;
        }
    }
}