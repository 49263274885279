*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.estoque-principal-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.estoque-all-container{
    max-width: 1538px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(228, 241, 252);

}

.estoque-menu-top-consulta{
    height: 50px;
    display: flex;
    background-color: rgba(128, 128, 128, 0.353);
    width: 100%;
    
}

.estoque-menu-top-consulta-left{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;

}

.estoque-menu-top-consulta-left select{
    height: 85%;
    width: 70%;
    margin-left: 15px;
    padding-left: 10px;
    color: grey;
}

.estoque-menu-top-consulta-right{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.estoque-menu-top-consulta-right select{
    height: 85%;
    width: 70%;
    margin-left: 15px;
    padding-left: 10px;
    color: grey;
    margin-right: 20px;
}


.carros-disponiveis{
    margin-top: 10px;
    width: 100%;
}

.carros-disponiveis h1{
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    margin-left: 28px;
    text-decoration: underline;
}


/* ESTILIZAÇÃO DO GRID */
.grid-container-car{
    margin-top: 10px;
    width: 100%;
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    text-decoration: none;


}

.container-all-cars{
    margin-top: 10px;
    justify-content: center;
    width: 250px;
    display: flex;
    grid-template-columns: 1fr;
    text-decoration: none;

}

/*ESTILIZAÇÃO PARA A PÁGINA EMPRESA*/

.imagem-content-ul{
    display: block;
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}

.link-carro-href{
    text-decoration: none;
}

.imagem-content-ul img{
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-decoration: none;

}

.grid-style{
    margin-left: 20px;
    width: 250px;
    height: 350px;
    border: solid 1px rgba(72, 72, 72, 0.352);
    border-radius: 5px;
    text-decoration: none;
    background-color: white;
    -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
    -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
    box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);

}

.info-content-ul{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;

}

.info-content-center{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 95%;
    text-decoration: none;

}

.info-carro-marca-modelo{
    width: 230px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.info-carro-marca-modelo ul{
    height: 100%;
    list-style: none;
    text-decoration: none;
    margin-top: 2px;
    font-family: Arial, Helvetica, sans-serif;
}

.info-carro-marca-modelo ul li{
    height: 13%;
    display: flex;
    text-decoration: none;
    align-items: center;
    text-decoration: none;

    
}

.info-carro-marca-modelo ul li span{
    margin-left: 10px;
    text-decoration: none;

}

.ano-carro{
    margin-top: 10px;
    color: grey;
    font-size: 10px;
    font-weight: bold;
}

.carro-marca-modelo{
    color: black;
    font-weight: bold;
    font-size: 15px;
}

.motor-combustivel-carroceria{
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.kilometragem{
    color: black;
    font-weight: bold;
    font-size: 11px;
}

.placa{
    color: black;
    font-family: bold;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;

}

.info-carro-preco{
    height: 50px;
    width: 95%;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.preco{
    color: rgb(209,0,0);
    font-size: 15px;
    font-weight: bold;
}

.adicionar-carro-800px{
    position: relative;
    color: rgb(209,0,0);
    font-size: 12px;
    text-decoration:underline;
    cursor: pointer;
}

.adicionar-carro-800px:hover{
    color: red;
}

.carro-cidade{
    font-size: 11px;
    margin-left: 5px;
    color: grey;
}

.carros-all-content-800px{
    display: none;
}

.menu-lateral-800px{display: none;}

.span-carro-length{
    font-size: 12px;
}

/* ~ ESTILIZAÇÃO DO GRID */

.carros-filtrados-zero{
    color: grey;
    font-size: 12px;
    margin-top: 20px;
    border: solid 1px rgb(255, 255, 255);
    height: 400px;
    width: 94%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
    -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
    box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);
    
}


.carro-error-carro-detalhes-container span{
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.form-success{
    font-size: 13px;
    color: rgb(3, 190, 3);
    font-weight: bold;
}

.info-carro-ano-cor{
    width: 90%;
}

.info-carro-ano-cor span{
    color: grey;
    font-size: 10px;
    font-weight: bold;
}

.info-carro-marca-outros{
    width: 90%;
}

.info-carro-marca-outros span{
    color: black;
    font-weight: bold;
    font-size: 13px;
}

.info-motor-combustivel-carroceria{
    width: 90%;
}

.info-motor-combustivel-carroceria{
    color: black;
    font-weight: bold;
    font-size: 10px;
}

.info-kilometragem{
    width: 90%;
}

.info-kilometragem span{
    color: black;
    font-weight: bold;
    font-size: 12px;
}

.info-preco{
    width: 90%;
    margin-top: 20px;
}

.info-preco{
    color: rgb(209, 0, 0);
    font-weight: bold;
    font-size: 15px;
}

.info-localizacao{
    width: 90%;
}

.info-localizacao{
    color: grey;
    font-size: 11px;
}



@media screen and (min-width: 350px) {
    .estoque-principal-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .estoque-all-container{
        max-width: 1538px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(228, 241, 252);
    
    }
    
    .estoque-menu-top-consulta{
        height: 50px;
        display: flex;
        background-color: rgba(128, 128, 128, 0.353);
        width: 100%;
        
    }
    
    .estoque-menu-top-consulta-left{
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
    
    }
    
    .estoque-menu-top-consulta-left select{
        height: 85%;
        width: 70%;
        margin-left: 15px;
        padding-left: 10px;
        color: grey;
    }
    
    .estoque-menu-top-consulta-right{
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    
    .estoque-menu-top-consulta-right select{
        height: 85%;
        width: 70%;
        margin-left: 15px;
        padding-left: 10px;
        color: grey;
        margin-right: 20px;
    }
    
    
    .carros-disponiveis{
        margin-top: 10px;
        width: 100%;
    }
    
    .carros-disponiveis h1{
        color: grey;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        margin-left: 28px;
        text-decoration: underline;
    }
    
    
    /* ESTILIZAÇÃO DO GRID */
    .grid-container-car{
        margin-top: 10px;
        width: 100%;
        display: grid;
        flex-direction: column;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    
    }
    
    .container-all-cars{
        margin-top: 10px;
        justify-content: center;
        width: 350px;
        display: flex;
        grid-template-columns: 1fr;
        text-decoration: none;
    
    }
    
    .imagem-content-ul{
        display: block;
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    
    }
    
    .link-carro-href{
        text-decoration: none;
    }
    
    .imagem-content-ul img{
        height: 100%;
        width: 100%;
        border-radius: 4px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        text-decoration: none;
    
    }
    
    .grid-style{
        margin-left: 0px;

        width: 300px;
        height: 400px;
        border: solid 1px rgba(72, 72, 72, 0.352);
        border-radius: 5px;
        text-decoration: none;
        background-color: white;
        -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);
    
    }
    
    .info-content-ul{
        width: 100%;
        display: flex;
        justify-content: center;
        text-decoration: none;
    
    }
    
    .info-content-center{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 95%;
        text-decoration: none;
    
    }
    
    .info-carro-marca-modelo{
        width: 290px;
        text-decoration: none;
    
    }
    
    .info-carro-marca-modelo ul{
        height: 100%;
        list-style: none;
        text-decoration: none;
        margin-top: 2px;
        font-family: Arial, Helvetica, sans-serif;
    }
    
    .info-carro-marca-modelo ul li{
        height: 13%;
        display: flex;
        text-decoration: none;
        align-items: center;
        text-decoration: none;
    
        
    }
    
    .info-carro-marca-modelo ul li span{
        margin-left: 10px;
        text-decoration: none;
    
    }
    
    .ano-carro{
        margin-top: 10px;
        color: grey;
        font-size: 13px;
        font-weight: bold;
    }
    
    .carro-marca-modelo{
        color: black;
        font-weight: bold;
        font-size: 15px;
    }
    
    .motor-combustivel-carroceria{
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
    
    .kilometragem{
        color: black;
        font-weight: bold;
        font-size: 13px;
    }
    
    .placa{
        color: black;
        font-family: bold;
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
    
    }
    
    .info-carro-preco{
        height: 50px;
        width: 95%;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    
    .preco{
        color: rgb(209,0,0);
        font-size: 20px;
        font-weight: bold;
    }
    
    .adicionar-carro-800px{
        position: relative;
        color: rgb(209,0,0);
        font-size: 12px;
        text-decoration:underline;
        cursor: pointer;
    }
    
    .adicionar-carro-800px:hover{
        color: red;
    }
    
    .carro-cidade{
        font-size: 13px;
        margin-left: 5px;
        color: grey;
    }
    
    .carros-all-content-800px{
        display: none;
    }
    
    .menu-lateral-800px{display: none;}
    
    .span-carro-length{
        font-size: 12px;
    }
    
    /* ~ ESTILIZAÇÃO DO GRID */
    
    .carros-filtrados-zero{
        color: grey;
        font-size: 12px;
        margin-top: 20px;
        border: solid 1px rgb(255, 255, 255);
        height: 400px;
        width: 94%;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);
        
    }
    
    
    .carro-error-carro-detalhes-container span{
        width: 90%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    
    .form-success{
        font-size: 13px;
        color: rgb(3, 190, 3);
        font-weight: bold;
    }
    
}




@media screen and (min-width: 500px) {
    .estoque-principal-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .estoque-all-container{
        max-width: 1538px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(228, 241, 252);
    
    }
    
    .estoque-menu-top-consulta{
        height: 50px;
        display: flex;
        background-color: rgba(128, 128, 128, 0.353);
        width: 100%;
        
    }
    
    .estoque-menu-top-consulta-left{
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
    
    }
    
    .estoque-menu-top-consulta-left select{
        height: 85%;
        width: 70%;
        margin-left: 15px;
        padding-left: 10px;
        color: grey;
    }
    
    .estoque-menu-top-consulta-right{
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    
    .estoque-menu-top-consulta-right select{
        height: 85%;
        width: 70%;
        margin-left: 15px;
        padding-left: 10px;
        color: grey;
        margin-right: 20px;
    }
    
    
    .carros-disponiveis{
        margin-top: 10px;
        width: 100%;
    }
    
    .carros-disponiveis h1{
        color: grey;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        margin-left: 28px;
        text-decoration: underline;
    }
    
    
    /* ESTILIZAÇÃO DO GRID */
    .grid-container-car{
        margin-top: 10px;
        width: 455px;
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    
    }
    
    .container-all-cars{
        margin-top: 10px;
        width: 94%;
        display: grid;
        grid-template-columns: 1fr;
        text-decoration: none;
    
    }
    
    .imagem-content-ul{
        display: block;
        height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    
    }
    
    .link-carro-href{
        text-decoration: none;
    }
    
    .imagem-content-ul img{
        height: 100%;
        width: 100%;
        border-radius: 4px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        text-decoration: none;
    
    }
    
    .grid-style{
        width: 100%;
        height: 500px;
        border: solid 1px rgba(72, 72, 72, 0.352);
        border-radius: 5px;
        text-decoration: none;
        background-color: white;
        -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);
    
    }
    
    .info-content-ul{
        display: flex;
        justify-content: center;
        text-decoration: none;
    
    }
    
    .info-content-center{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        text-decoration: none;
    
    }
    
    .info-carro-marca-modelo{
        width: 405px;
        text-decoration: none;
    
    }
    
    .info-carro-marca-modelo ul{
        height: 100%;
        list-style: none;
        text-decoration: none;
        margin-top: 2px;
        font-family: Arial, Helvetica, sans-serif;
    }
    
    .info-carro-marca-modelo ul li{
        height: 13%;
        display: flex;
        text-decoration: none;
        align-items: center;
        text-decoration: none;
    
        
    }
    
    .info-carro-marca-modelo ul li span{
        margin-left: 10px;
        text-decoration: none;
    
    }
    
    .ano-carro{
        margin-top: 10px;
        color: grey;
        font-size: 13px;
        font-weight: bold;
    }
    
    .carro-marca-modelo{
        color: black;
        font-weight: bold;
        font-size: 20px;
    }
    
    .motor-combustivel-carroceria{
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
    
    .kilometragem{
        color: black;
        font-weight: bold;
        font-size: 13px;
    }
    
    .placa{
        color: black;
        font-family: bold;
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
    
    }
    
    .info-carro-preco{
        height: 50px;
        width: 95%;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    
    .preco{
        color: rgb(209,0,0);
        font-size: 20px;
        font-weight: bold;
    }
    
    .adicionar-carro-800px{
        position: relative;
        color: rgb(209,0,0);
        font-size: 12px;
        text-decoration:underline;
        cursor: pointer;
    }
    
    .adicionar-carro-800px:hover{
        color: red;
    }
    
    .carro-cidade{
        font-size: 13px;
        margin-left: 5px;
        color: grey;
    }
    
    .carros-all-content-800px{
        display: none;
    }
    
    .menu-lateral-800px{display: none;}
    
    .span-carro-length{
        font-size: 12px;
    }
    
    /* ~ ESTILIZAÇÃO DO GRID */
    
    .carros-filtrados-zero{
        color: grey;
        font-size: 12px;
        margin-top: 20px;
        border: solid 1px rgb(255, 255, 255);
        height: 400px;
        width: 94%;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);
        
    }
    
    
    .carro-error-carro-detalhes-container span{
        width: 90%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    
    .form-success{
        font-size: 13px;
        color: rgb(3, 190, 3);
        font-weight: bold;
    }
    
}

@media screen and (min-width: 770px){
    .grid-container-car{
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr); /* Duas colunas */
        width: 700px;
    }

    .imagem-content-ul{
        display: block;
        height: 200px;
        display: flex;
        justify-content: start;
        align-items: start;
        text-decoration: none;
    
    }
    
    .link-carro-href{
        text-decoration: none;
    }
    
    .imagem-content-ul img{
        height: 100%;
        width: 100%;
        border-radius: 4px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        text-decoration: none;
    
    }
    
    .grid-style{
        width: 250px;
        height: 500px;
        border: solid 1px rgba(72, 72, 72, 0.352);
        border-radius: 5px;
        text-decoration: none;
        background-color: white;
    
    }

    .deletando-carro{
        width: 250px;
        height: 500px;
        border: solid 3px rgb(255, 0, 0);
        border-radius: 5px;
        text-decoration: none;
        cursor:pointer;
    }


    
    .info-content-ul{
        margin-top: 30px;
        height: 45%;
        display: flex;
        justify-content: center;
        text-decoration: none;
    
    }
    
    .info-content-center{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        text-decoration: none;
    
    }
    
    .info-carro-marca-modelo{

        width: 250px;
        text-decoration: none;
    }
    
    .info-carro-marca-modelo ul{
        height: 100%;
        list-style: none;
        text-decoration: none;
        margin-top: 2px;
        font-family: Arial, Helvetica, sans-serif;
    }
    
    .info-carro-marca-modelo ul li{
        height: 13%;
        display: flex;
        text-decoration: none;
        align-items: center;
        text-decoration: none;

        
    }
    
    .info-carro-marca-modelo ul li span{
        margin-left: 10px;
        text-decoration: none;
        
    
    }
    
    .ano-carro{
        margin-top: 10px;
        color: grey;
        font-size: 13px;
        font-weight: bold;
    }
    
    .carro-marca-modelo{
        color: black;
        font-weight: bold;
        font-size: 13px;
        
    }
    
    .motor-combustivel-carroceria{
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
    
    .kilometragem{
        color: black;
        font-weight: bold;
        font-size: 13px;
    }
    
    .placa{
        color: black;
        font-family: bold;
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
    
    }
    
    .info-carro-preco{
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 0;
        justify-content: start;

    }
    
    .preco{
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
    }
    
    .carro-cidade{
        font-size: 13px;
        margin-left: 10px;
        color: grey;
    }

    .carros-all-content-800px{
        display: none;
    }

    .adicionar-carro-800px{
        position: relative;
        color: rgb(209,0,0);
        font-size: 12px;
        text-decoration:underline;
        cursor: pointer;
        
    }

    .adicionar-carro-800px:hover{
        color: red;
    }

    .delete-carro-800px{
        position: relative;
        color: rgb(209,0,0);
        font-size: 12px;
        text-decoration:underline;
        cursor: pointer;
        margin-left: 40px;
    }

    .delete-carro-800px:hover{
        color: red;

    }


    .info-carro-ano-cor{
        width: 90%;
    }

    .info-carro-ano-cor span{
        color: grey;
        font-size: 12px;
        font-weight: bold;
    }

    .info-carro-marca-outros{
        width: 90%;
    }

    .info-carro-marca-outros span{
        color: black;
        font-weight: bold;
        font-size: 20px;
    }

    .info-motor-combustivel-carroceria{
        width: 90%;
    }

    .info-motor-combustivel-carroceria{
        color: black;
        font-weight: bold;
        font-size: 14px;
    }

    .info-kilometragem{
        width: 90%;
    }

    .info-kilometragem span{
        color: black;
        font-weight: bold;
        font-size: 14px;
    }

    .info-preco{
        width: 90%;
        margin-top: 50px;
    }

    .info-preco{
        color: rgb(209, 0, 0);
        font-weight: bold;
        font-size: 20px;
    }
    
    .info-localizacao{
        width: 90%;
        font-size: 13px;
    }

    .info-localizacao{
        color: grey;
    }

}

@media screen and (min-width: 1000px){
    .grid-container-car{
        display: none;
    }

    .carros-disponiveis{
        display: none;
    } .estoque-menu-top-consulta-left select{
        display: none;
    }

    .estoque-menu-top-consulta-right select{
        display: none;
    }

    .estoque-all-container{
        display: flex;
        flex-direction: row;
        
        align-items: start;
    }


    .grid-container-car-800px{
        width: 97%;
        height: 94.5%;
        display: grid;
        overflow-y: scroll;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr); /* Duas colunas  */
        margin-left: 15px;
        margin-left: 20px;

    }

  

    .carros-all-content-800px{
        width: 80%;
        height: 712px;

    }

    .carros-disponiveis-800px{
        color: grey;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 17px;
        margin-top: 20px;
        border-bottom: solid 1px rgba(128, 128, 128, 0.233);

    }

    .carros-disponiveis-h1-800px{
        margin-left: 20px;
        font-size: 16px;

    }

    .carros-all-content-800px{
        display: block;
    }
    .carros-disponiveis-h1-800px{
        margin-top: 20px;
        margin-left: 20px;
        font-size: 16px;
        color: grey;
    }


    .menu-lateral-800px{
        overflow-y: scroll;
        display: block;
        width: 250px;
        height: 710px;
        background-color: rgb(228, 241, 252);
        border-right: solid 1px rgba(128, 128, 128, 0.233);

    }

    .estoque-menu-top-consulta{
        height: 710px;
        display: flex;
        position: relative;
        background-color: rgba(128, 128, 128, 0.353);
        width: 300px;

    }

    .estoque-menu-side-800px{
        height: 100%;
        width: 90%;
        margin-left: 20px;
    }

    .estoque-menu-side-800px h4{
        margin-top: 22.5px;
        color: grey;
        font-family: Arial, Helvetica, sans-serif;

        text-decoration: underline;
    }

    .mside-marcas-container-960px {
        color: rgb(209,0,0);
        margin-top: 20px;
        font-weight: bold;
    }

    .mside-marcas-content-960px{
        margin-top: 20px;
        
    }

    .mside-marcas-content-960px ul{
        list-style: none;
    }

    .mside-marcas-content-960px ul li{
        margin-top: 10px;
    }
    
    .mside-marcas-content-960px ul li input{
        height: 10px;
    }

    .mside-marcas-content-960px ul li label{
        color: rgb(89, 89, 89);
        font-weight: bold;
        font-size: 12px;
        margin-left: 5px;
    }

    .mside-preco-container-960px{
        margin-top: 20px;
        color: rgb(209,0,0);
        margin-top: 20px;
        font-weight: bold;
    }

    .mside-preco-content-960px{
        margin-top: 20px;
    }

    .mside-preco-content-960px ul{
        list-style: none;
    }

    .mside-preco-content-960px ul li{
        margin-top: 10px;
    }
    
    .mside-preco-content-960px ul li input{
        height: 10px;
    }

    .mside-preco-content-960px ul li label{
        color: rgb(89, 89, 89);
        font-weight: bold;
        font-size: 12px;
        margin-left: 5px;
    }

    .estoque-menu-top-consulta{
        display: none;
        
    }

    .adicionando-carro-mobile{

        overflow-y: scroll;
        margin-top: 20px;
        width: 82%;
        top: 90px;
        height: 690px;
        background-color: white;
        border: solid 1px grey;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .adicionando-carro-mobile form{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .adicionando-carro-mobile h1{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        color: rgb(209,0,0);
        font-weight: bold;
        margin-top: 10px;
    }

    .adicionando-carro-800px-centralized{
        width: 90%;
        margin-top: 5px;
        height: 50%;
        display: flex;
    }

    .adicionando-carro-800px-left{
        width: 33.3%;
        height: 100%;
    }

    .adicionando-carro-800px-center{
        width: 33.3%;
        height: 100%;
    }

    .adicionando-carro-800px-right{
        width: 33.3%;
        height: 100%;
    }

    .adicionando-carro-800px-ul{
        height: 100%;
        width: 100%;
        list-style: none;
    }

    .adicionando-carro-800px-ul li{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .adicionando-carro-800px-ul li label{
        color: grey;
        font-weight: bold;
        font-size: 11px;
    }

    .adicionando-carro-800px-ul li input{
        margin-top: 5px;
        width: 90%;
        height: 35px;
        border: solid 1px grey;
        padding-left: 10px;
        border-radius: 5px;
    }

    .adicionando-carro-800px-ul li .adicionando-carro-preco{
        color: rgb(209,0,0);
    }
    
    .opcionais-carro-adicionando{
        width: 90%;
        height: 200px;
        display: flex;

    }

    .opcionais-carro-adicionando label{
        color: grey;
        font-weight: bold;
        font-size: 13px;
    }

    .opcionais-carro-adicionando textarea{
        margin-top: 5px;
        width: 90%;
        height: 70px;
    }

    .cor{
        width: 33.3%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cor li{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .cor li label{
        color: grey;
        font-weight: bold;
        font-size: 11px;
    }

    .cor li input{
        margin-top: 5px;
        width: 90%;
        height: 35px;
        border: solid 1px grey;
        padding-left: 10px;
        border-radius: 5px;
    }

    .opcionais{
        width: 67%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .adicionando-carro-botao-registrar{
        border: none;
        background-color: rgb(0, 89, 255);
        width: 150px;
        margin-left: 120px;
        height: 50px;
        cursor: pointer;
    }

    .adicionando-carro-botao-registrar:hover{
        background-color: blue;
    }

    .certeza-del-carro{
        position: fixed;
        top: 0%;
        left: 0;
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: appearCaixaDel2 0.4s forwards;
    }

    @keyframes appearCaixaDel2 {
        0%{
            top: -100%;
            opacity: 0;
        } 50%{
            opacity: 0.4;
        } 
        100%{
            top: 0%;
            opacity: 1;
        }
    }

    .caixa-pergunta-del-carro{
        border: solid 3px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;
        height: 150px;
        background-color: white;
        -webkit-box-shadow: 2px 10px 44px 12px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 10px 44px 12px rgba(0,0,0,0.75);
        box-shadow: 2px 10px 44px 12px rgba(0,0,0,0.75);
    }

    .caixa-pergunta-del-carro h1{
        margin-top: 20px;
        color: rgb(0, 0, 0);
        font-size: 14px;
    }

    .separacao-caixa-sim-nao{
        width: 100%;
        margin-top: 20px;
        height: 50px;
        display: flex;
    }

    .caixa-delentando-sim{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
    }

    .caixa-delentando-sim span{
        margin-top: 0;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: rgb(0, 255, 34);
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;

    }

    .caixa-delentando-nao{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
    }

    .caixa-delentando-nao span{
        margin-top: 0;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: rgb(255, 0, 0);
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
    }

    .certeza-del-carro-1000px{
        display: none;
    }

    .carros-filtrados-zero{
        color: grey;
        font-size: 12px;
        margin-top: 20px;
        border: solid 1px rgb(255, 255, 255);
        height: 400px;
        width: 250px;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        -moz-box-shadow: 5px 5px 0px -1px rgba(72, 72, 72, 0.75);
        box-shadow: 5px 5px 20px -1px rgba(72, 72, 72, 0.75);
        
    }
    

    
}



@media screen and (min-width: 1300px){

    .grid-container-car-800px{
        width: 97%;
        height: 94.5%;
        display: grid;
        overflow-y: scroll;
        gap: 00px;
        grid-template-columns: repeat(4, 1fr); /* Duas colunas  */
        margin-left: 20px;

    }

    .carros-all-content-800px{
        width: 90%;
        height: 712px;
        
    }

    .carros-disponiveis-800px{
        color: grey;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 17px;
        margin-top: 2px;
        border-bottom: solid 1px rgba(128, 128, 128, 0.233);
        display: flex;
        user-select: none;
    }

    .carros-disponiveis-h1-800px{
        margin-left: 20px;
        font-size: 16px;
    }

    
    .adicionando-carro-mobile{
        max-width: 1290px;

        overflow-y: scroll;
        margin-top: 20px;
        width: 82%;
        top: 90px;
        height: 690px;
        background-color: white;
        border: solid 1px grey;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .adicionar-carro-800px{

        position: relative;
        color: rgb(209,0,0);
        font-size: 12px;
        text-decoration:underline;
        cursor: pointer;
    }

    .adicionar-carro-800px:hover{
        color: red;
    }

    .certeza-del-carro{
        position: absolute;
        width: 84%;
        height: 85%;
    }


    .certeza-del-carro{
        display: none;
    }

    .certeza-del-carro-1000px{
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: appearCaixaDel 0.4s forwards;
        
    }
    /*carros-filtrados-zero*/

    .caixa-pergunta-del-carro{
        border: solid 3px black;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;
        height: 150px;
        background-color: white;
        -webkit-box-shadow: 2px 10px 44px 12px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 10px 44px 12px rgba(0,0,0,0.75);
        box-shadow: 2px 10px 44px 12px rgba(0,0,0,0.75);
    }

    .caixa-pergunta-del-carro h1{
        margin-top: 20px;
        color: rgb(0, 0, 0);
        font-size: 14px;
    }

    .separacao-caixa-sim-nao{
        width: 100%;
        margin-top: 20px;
        height: 50px;
        display: flex;
    }

    .caixa-delentando-sim{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
    }

    .caixa-delentando-sim span{
        margin-top: 0;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: rgb(0, 255, 34);
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;

    }

    .caixa-delentando-nao{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
    }

    .caixa-delentando-nao span{
        margin-top: 0;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: rgb(255, 0, 0);
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
    }

    @keyframes appearCaixaDel {
        0%{
            top: -100%;
            opacity: 0;
        } 50%{
            opacity: 0.1;
        } 
        100%{
            top: 0;
            opacity: 1;
        }
    }
}