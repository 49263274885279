* {
    padding: 0;
    margin: 0;
}

/* Contêiner principal com largura máxima de 1400px */
.empresa-all-container {
    z-index: 100;
    width: 100%;
    max-width: 1538px;
    margin: 0 auto; /* Centraliza o contêiner */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden; /* Impede rolagem horizontal */
}

/* Estilos para imagens dentro do contêiner */
.empresa-images-informacoes {
    width: 100%;
}

.empresa-images-informacoes a {
    width: 100%;
}

.empresa-images-informacoes img {
    width: 100%;
    height: auto; /* Mantém a proporção das imagens */
}

/* Estilos para o texto "carros abaixo" */
.carros-abaixo {
    margin-top: 30px;
    color: rgb(255, 255, 255);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}

.empresa-carros-demostracao-700px-menos{
    width: 100%;
    display: flex;
    justify-content: center;
}

.empresa-carros-demostracao-700px-menos-centralized{
    width: 80%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr); 
    gap: 20px;
    margin-right: 10px;
}
/* Estilos para os contêineres dos carros */
.container-all-cars {
    margin-left: 13px;
}


/* Estilos para a seção de carros disponíveis */
.empresa-carros-disponiveis {
    margin-top: 2px;
    position: relative;
    width: 92%;
    height: 50px;
    align-items: center;
    display: flex;
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-decoration: underline;
}

/* Botão de conferir veículos */
.conferir-veiculos-butao {
    margin-top: 50px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 10px;
    height: 60px;
    width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(209, 0, 0);
    font-size: 13px;
}


.conferir-veiculos-butao:hover {
    background-color: rgb(139, 0, 0);
}

.empresa-carros-demonstracao{
    display: none;
}

.align-start{
    align-items: start;
}

.margin-top-10px-300px{
    margin-top: calc(1em);
}


@media screen and(min-width: 380px) {
    .empresa-all-container {
        z-index: 100;
        width: 100%;
        max-width: 1538px;
        margin: 0 auto; /* Centraliza o contêiner */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        overflow-x: hidden; /* Impede rolagem horizontal */
    }
    
    /* Estilos para imagens dentro do contêiner */
    .empresa-images-informacoes {
        width: 100%;
    }
    
    .empresa-images-informacoes a {
        width: 100%;
    }
    
    .empresa-images-informacoes img {
        width: 100%;
        height: auto; /* Mantém a proporção das imagens */
    }
    
    /* Estilos para o texto "carros abaixo" */
    .carros-abaixo {
        margin-top: 30px;
        color: rgb(255, 255, 255);
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 12px;
    }
    
    .empresa-carros-demostracao-700px-menos{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .empresa-carros-demostracao-700px-menos-centralized{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(1, 1fr); 
        gap: 20px;
    }
    /* Estilos para os contêineres dos carros */
    .container-all-cars {
        margin-left: 13px;
    }
    
    
    /* Estilos para a seção de carros disponíveis */
    .empresa-carros-disponiveis {
        margin-top: 2px;
        position: relative;
        width: 92%;
        height: 50px;
        align-items: center;
        display: flex;
        color: grey;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-decoration: underline;
    }
    
    /* Botão de conferir veículos */
    .conferir-veiculos-butao {
        margin-top: 50px;
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        border-radius: 10px;
        height: 60px;
        width: 200px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(209, 0, 0);
        font-size: 13px;
    }
    
    
    .conferir-veiculos-butao:hover {
        background-color: rgb(139, 0, 0);
    }
    
    .empresa-carros-demonstracao{
        display: none;
    }
    
    .align-start{
        align-items: start;
    }
    
    .margin-top-10px-300px{
        margin-top: calc(1em);
    }
}
/*width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr); 1 coluna por padrão 
    gap: 20px; Adiciona espaço entre os itens */

@media screen and(min-width: 512px) {
.margin-top-10px-300px{
    margin-top: 40px;
}
    /* Contêiner principal com largura máxima de 1400px */
.empresa-all-container {
    z-index: 100;
    width: 100%;
    max-width: 1538px;
    margin: 0 auto; /* Centraliza o contêiner */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden; /* Impede rolagem horizontal */
}

/* Estilos para imagens dentro do contêiner */
.empresa-images-informacoes {
    width: 100%;
}

.empresa-images-informacoes a {
    width: 100%;
}

.empresa-images-informacoes img {
    width: 100%;
    height: auto; /* Mantém a proporção das imagens */
}

/* Estilos para o texto "carros abaixo" */
.carros-abaixo {
    margin-top: 30px;
    color: rgb(255, 255, 255);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}

.empresa-carros-demostracao-700px-menos{
    width: 100%;
    display: flex;
    justify-content: center;
}

.empresa-carros-demostracao-700px-menos-centralized{
    border: solid 1px blue;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 20px;
}
/* Estilos para os contêineres dos carros */
.container-all-cars {
    margin-left: 13px;
}


/* Estilos para a seção de carros disponíveis */
.empresa-carros-disponiveis {
    margin-top: 2px;
    position: relative;
    width: 92%;
    height: 50px;
    align-items: center;
    display: flex;
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-decoration: underline;
}

/* Botão de conferir veículos */
.conferir-veiculos-butao {
    margin-top: 50px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 10px;
    height: 60px;
    width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(209, 0, 0);
    font-size: 13px;
}


.conferir-veiculos-butao:hover {
    background-color: rgb(139, 0, 0);
}

.empresa-carros-demonstracao{
    display: none;
}

.align-start{
    align-items: start;
}

    
}

/* Para telas com largura mínima de 770px */
@media screen and (min-width: 770px) {
    .empresa-carros-demostracao-700px-menos{
        display: none;
    }
    .empresa-carros-demonstracao {
        grid-template-columns: repeat(3, 1fr); /* 3 colunas */
        width: 95%;
        display: grid;
        margin-top: 5px;
        gap: 20px; /* Adiciona espaço entre os itens */
    }

    .container-all-cars {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .align-start{
        align-items: center;
    }

    .margin-top-0-770px{
        margin-top: 0;
    }
}

/* Para telas com largura mínima de 830px */
@media screen and (min-width: 830px) {
    .empresa-images-informacoes-1200px {
        display: none;
    }

    .empresa-images-informacoes {
        display: none;
    }
}

/* Para telas com largura mínima de 1000px */
@media screen and (min-width: 1000px) {
    .empresa-images-informacoes-1200px {
        display: none;
    }
    .empresa-carros-demonstracao {
        grid-template-columns: repeat(4, 1fr); /* 4 colunas */
        max-width: 1538px;

    }

    .empresa-images-informacoes {
        max-width: 1538px;

        width: 100%;
    }

    .empresa-images-informacoes img {
        max-width: 1538px;

        height: auto;
    }
}

/* Para telas com largura mínima de 1400px */
@media screen and (min-width: 1400px) {
    .empresa-images-informacoes {
        display: none;
    }

    .empresa-carros-demonstracao {
        grid-template-columns: repeat(5, 1fr); /* 5 colunas */
        max-width: 1400px; /* Limita a largura */

    }

    /* Limita a largura do contêiner a 1400px */
    .empresa-all-container {
        max-width: 1400px; /* Limita a largura */
    }

    /* Esconde imagens em telas grandes */
    .empresa-images-informacoes-1200px {
        max-width: 1400px; /* Limita a largura */

        display: none;
        width: 100%;
    }

    .empresa-images-informacoes-1200px a {
        max-width: 1400px; /* Limita a largura */

        width: 100%;
    }

    .empresa-images-informacoes-1200px img {
        max-width: 1538px;
        width: 100%;
    }
}