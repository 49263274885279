*{
    padding: 0;
    margin: 0;
}

.sec-all-container-login{
    background: linear-gradient(to bottom, rgb(20, 2, 2), rgb(44, 1, 1));
    height: 750px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.sec-principal-container-login{
    border: solid 1px rgb(255, 255, 255);
    background-color: aliceblue;
    border-radius: 10px;
    width: 300px;
    height: 50%;
    margin-top: 100px;
}

.sec-title-login{
    height: 10%;
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sec-title-login h1{
    font-size: 13px;
    font-weight: 700;
}

.sec-fields-login{
    flex-direction: column;
    display: flex;
    height: 20%;
    margin-top: 10px;
}

.sec-fields-login:nth-of-type(4){
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec-fields-login label{
    color: black;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
}

.sec-fields-login input{
    width: 90%;
    margin-left: 10px;
    height: 40px;
    padding-left: 5px;
    color: black;
}

.sec-principal-container-login hr{
    margin-top: 20px;
    color: black;
    font-weight: bold;
}

.sec-fields-login button{
    margin-top: 10px;
    height: 50px;
    width: 50%;
    color: black;
    font-weight: bold;
    background-color: white;
}

.sec-fields-login button:hover{
    color: rgb(22, 22, 22);
    background-color: rgb(207, 206, 206);
    cursor: pointer;
}

.login-error{
    color: rgb(209, 0, 0);
    display: flex;
    justify-content: center;
    text-align: center;
}
