*{
    padding: 0;
    margin: 0;
}

.header-contato-800px{
    display: none;
}

.header-all-container{
    user-select: none;
    z-index: 10000;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
}

.header-container-principal{
    width: 100%;
    height: 100px;
    max-width: 1538px;
    border-bottom: solid 1px rgba(128, 128, 128, 0.389);

    display: flex;
}

/* ESTILIZAÇÃO HEADER PARTE ESQUERDA */
.header-left-container{
    height: 100%;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-left-container img{
    height: 80%;
    width: 80%;
    margin-left: 20px;
}

/* ESTILIZAÇÃO DA PARTE ESQUEDA FINALIZADA*/



/* ESTILIZAÇÃO HEADER PARTE DIREITA */

.header-right-container{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.header-right-container img{
    height: 30px;
    width: 30px;
    margin-right: 20px;
    cursor: pointer;
}


/* ESTILIZAÇÃO DA PARTE DIREITA FINALIZADA*/





/* ESTILIZAÇÃO HEADER MOBILE MENU */

.header-menu-open-mobile{
    position: relative;
    width: 100%;
    height: 300px;
    z-index: 1000;
}


.header-menu-open-mobile .header-menu-open-mobile-ul{
    z-index: 1000;
    height: 100%;
    width: 100%;
    list-style: none;
}

.header-menu-open-mobile .header-menu-open-mobile-ul li{
    z-index: 1000;

    width: 100%;
    height: 17%;
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    border-top: solid 1px rgba(128, 128, 128, 0.45);
}

.header-menu-open-mobile .header-menu-open-mobile-ul li a{
    z-index: 1000;

    margin-left: 17px;
    color: #00ccff;
    text-decoration: none;
    font-weight: bold;
    font-size: 13px;
}

.header-menu-open-mobile .header-menu-open-mobile-ul li a:hover{
    color: rgba(0, 196, 210, 0.702);
}

.header-info-contato .header-info-contato-ul{
    z-index: 1000;
    display: flex;
    height: 100%;
    width: 100%;
    border-top: none;
}

.header-info-contato .header-info-contato-ul li{
    border-top: none;
    height: 100%;
    display: flex;
    width: 300px;
}

.header-info-contato .header-info-contato-ul li img{
    height: 20px;
}

.header-info-contato .header-info-contato-ul li span{
    font-weight: bold;
    user-select: all;
    font-size: 10px;
}

.header-info-contato .header-info-contato-ul li:nth-last-child(1) img{
    margin-left: 10px;
}

.header-info-contato .header-info-contato-ul li:nth-last-child(2) img{
    margin-left: 10px;
}


.header-info-contato .header-info-contato-ul li:nth-last-child(1) span{
    margin-left: 4px;
}

.header-info-contato .header-info-contato-ul li:nth-last-child(2) span{
    color: rgb(0, 0, 0);
}

.header-info-contato .header-info-contato-ul .whatsapp-content{
    border: solid 1px red;
}

.header-info-contato .header-info-contato-ul li:nth-last-child(2){
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-contato img{
    height: 20px;
    margin-left: 16px;
}

.icon-contato-whats img{
    height: 30px;
    margin-left: 16px;
    
}

.margin-left-0{
    position: relative;
    left: -13px;
}

.contato-content{
    font-weight: bold;
    position: relative;
    left: 6px;
}

/* ESTILIZAÇÃO DO MOBILE MENU*/



/* CRIANDO ANIMAÇÕES */


/* FINALIZANDO ANIMAÇÕES*/

.header-right-container-800px{
    display: none;
}

.header-right-container-800px-contato{
    display: none;
}

.whatsapp-content a{
    color: black;
}

.header-menu-open-mobile .header-menu-open-mobile-ul li .color-black{
    color: black;
}

.header-menu-open-mobile .header-menu-open-mobile-ul li .whats-icon{
    height: 25px;
}

.header-menu-open-mobile .header-menu-open-mobile-ul li .email-icon{
    width: 25px;
}

@media screen and (min-width: 830px) {
    .header-right-container{
        width: 80%;
        display: block;
    }

    

    .header-right-container .header-right-container-800px{
        display: block;
        height: 100%;
    }
    
    .header-right-container .header-right-container-800px ul{
        height: 100%;
        width: 100%;
        display: flex;
        list-style: none;
        justify-content: space-around;
    }

    .header-right-container .header-right-container-800px ul li{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    .header-right-container .header-right-container-800px ul li a{
        color: #00ccff;
        text-decoration: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        font-weight: bold;
    }

    .header-right-container .header-right-container-800px ul li a:hover{
        color: rgba(0, 196, 210, 0.702);
        border-bottom: solid 2px rgba(0, 196, 210, 0.702);
    }



    /* EXCLUINDO ITENS DO MOBILE */
    .header-menu-open-mobile{
        display: none;
    }

    .header-right-container img{
        display: none;
    }
    
    /* FIM EXCLUINDO ITENS DO MOBILE */
    .header-contato-800px{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-contato-800px img{
        height: 40px;
        width: 40px;
        border-radius: 100px;
    }

    .header-contato-800px a:nth-of-type(3){
        width: 75px;
    }

    
    .header-contato-800px img:nth-of-type(1){
        margin-right: 10px;
    }

    .header-contato-800px a img:nth-of-type(3){
        width: 55px;
        margin-left: 5px;
    }
}

:root{
    --vermelho: rgb(209,0,0);
}

@media screen and (min-width: 1300px) {
        
    .header-container-principal{
        height: 70px;
        max-width: 1538px;

    }

    .header-left-container{
        width: 20%;
        max-width: 1538px;

    }

    .header-left-container img{
        width: 135px;
        margin-left: 0px;
        max-width: 1538px;

        margin-top: 5px;
    }

    .header-right-container{
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1538px;

    }

    

    .header-right-container .header-right-container-800px{
        display: block;
        max-width: 1538px;

        height: 100%;
    }
    
    .header-right-container .header-right-container-800px ul{
        height: 100%;
        width: 800px;
        display: flex;
        list-style: none;
        justify-content: space-around;
        max-width: 1538px;

    }

    .header-right-container .header-right-container-800px ul li{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1538px;

       
    }

    .header-right-container .header-right-container-800px ul li img{
        height: 60px;
        width: 60px;
        display: block;
        max-width: 1538px;

    }

    .header-contato-800px{
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1538px;

    }

    .header-contato-800px img{
        max-width: 1538px;

        height: 40px;
        width: 40px;
        border-radius: 100px;
    }

    .header-contato-800px a:nth-of-type(3){
        width: 75px;
    }

    
    .header-contato-800px img:nth-of-type(1){
        margin-right: 10px;
    }

    .header-contato-800px a img:nth-of-type(3){
        width: 55px;
        margin-left: 5px;
    }
}