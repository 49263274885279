*{
    padding: 0;
    margin: 0;
}



.homeheader-all-container{
    border-bottom: solid 1px rgba(128, 128, 128, 0.389);
    user-select: none;
    z-index: 10000;
    top: 0;
}

.homeheader-container-principal{
    height: 100px;
    
    display: flex;
}

.homeheader-left-container{
    height: 100%;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeheader-left-container img{
    height: 80%;
    width: 80%;
    margin-left: 20px;
}

.homeheader-right-container{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.homeheader-right-container img{
    height: 30px;
    width: 30px;
    margin-right: 20px;
    cursor: pointer;
}

.homeheader-container-top-830px{
    display: none;
}

.homeheader-container-bottom-830px{
    display: none;
}
.header-menu-open-mobile .header-menu-open-mobile-ul li .color-black{
    color: black;
}


@media screen and (min-width: 830px) {
    
    .homeheader-container-principal{
        display: none;
    }

    .header-menu-open-mobile{
        display: none;
    }

    .homeheader-container-top-830px{
        display: flex;
        width: 100%;
        z-index: 999;
    }
    

    .homeheader-container-top-left-830px{
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00ccff;
        height: auto;
        border-right: solid 10px grey;

    }

    .homeheader-container-top-left-830px a{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .homeheader-container-top-left-830px img{
        width: 60%;
    }

    .homeheader-container-top-right-830px{
        width: 70%;
    }

    .homeheader-container-top-right-830px img{
        width: 100%;
        height: 100%;
    }










    .homeheader-container-bottom-830px{
        display: block;
        position: relative;
        display: flex;
        top: -5px;
        z-index: 1000;
        height: 50px;
        background-color: grey;
    }

    .homeheader-container-bottom-left-830px{
        height: 100%;
        width: 30%;
    }

    .homeheader-container-bottom-left-830px ul{
        width: 100%;
        height: 100%;
        display: flex;
        list-style: none;
        justify-content: center;
    }

    .homeheader-container-bottom-left-830px ul li{
        width: 20%;
    }

    .homeheader-container-bottom-left-830px ul li a{
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .homeheader-container-bottom-left-830px ul li a img{
        width: 70%;
        border-radius: 5px;
    }
    
    .homeheader-container-bottom-left-830px ul li a .logowhatsapp{
        width: 80%;
    }




    .homeheader-container-bottom-right-830px{
        width: 70%;
        height: 100%;
    }

    .homeheader-container-bottom-right-830px ul{
        height: 100%;
        width: 100%;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .homeheader-container-bottom-right-830px ul li{
        height: 100%;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .homeheader-container-bottom-right-830px ul li a{
        text-decoration: none;
        color: white;
        font-size: 15px;
        font-weight: bold;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .homeheader-container-bottom-right-830px ul li a:hover{
        color: #00ccff;
    }
    
}


@media screen and (min-width: 1040px) {
    .homeheader-container-bottom-left-830px ul li a img{
        width: 50%;
    }
    
    .homeheader-container-bottom-left-830px ul li a .logowhatsapp{
        width: 55%;
    }
}



@media screen and (min-width: 1390px) {
    .homeheader-container-bottom-left-830px ul li a img{
        width: 40%;
    }
    
    .homeheader-container-bottom-left-830px ul li a .logowhatsapp{
        width: 45%;
    }

    .homeheader-principal-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .homeheader-container-top-830px{
        display: flex;
        max-width: 1538px;
        width: 100%;
        z-index: 999;
    }

    .homeheader-container-bottom-830px{
        display: block;
        position: relative;
        display: flex;
        top: -5px;
        z-index: 1000;
        max-width: 1538px;

        height: 50px;
        background-color: grey;
    }
}